import Constants from "../config/constants";
import { bboxPolygon } from "@turf/turf";

const getExtent = cfg => {
  const extent = cfg.extent;

  return {
    north: extent.north + cfg.delta.north,
    south: extent.south + cfg.delta.north,
    west: extent.west + cfg.delta.west,
    east: extent.east + cfg.delta.west
  };
};

const getImageIdFromCoordinate = (long, lat) => {
  const cfg = Constants.MAP.INSTANT_SEARCH.CONFIG["shanghai_19_01"];
  if (long <= cfg.extent.east) {
    return "shanghai_19_01";
  }

  return "shanghai_19_02";
};

const getGridPosition = (image_id, long, lat) => {
  const cfg = Constants.MAP.INSTANT_SEARCH.CONFIG[image_id];
  const extent = getExtent(cfg);

  if (
    long > extent.east ||
    long < extent.west ||
    lat < extent.south ||
    lat > extent.north
  ) {
    return false;
  }

  const row = Math.abs(
    Math.trunc(
      Math.round(((lat - extent.north + Number.EPSILON) / cfg.dLat) * 10e6) /
        10e6
    )
  );
  const col = Math.abs(
    Math.trunc(
      Math.round(((long - extent.west + Number.EPSILON) / cfg.dLong) * 10e6) /
        10e6
    )
  );

  return { col, row, extent: _calculateExtentFromCollumnRow(col, row, cfg) };
};

const getGridFeatureFromPosition = (image_id, long, lat) => {
  const position = getGridPosition(image_id, long, lat);

  if (!position) {
    return false;
  }

  const { col, row, extent } = position;
  const cfg = Constants.MAP.INSTANT_SEARCH.CONFIG[image_id];
  const row_fill = row.toString().padStart(4, "0");
  const col_fill = col.toString().padStart(4, "0");

  const result = cfg.template
    .replace("{row}", row_fill)
    .replace("{col}", col_fill);

  return { imageName: result, geometry: bboxPolygon(extent) };
};

const _calculateExtentFromCollumnRow = (col, row, cfg) => {
  const extent = getExtent(cfg);
  const west = extent.west + col * cfg.dLong;
  const east = west + cfg.dLong;
  const north = extent.north - row * cfg.dLat;
  const south = north - cfg.dLat;

  return [west, south, east, north];
};

const getImageSet = image_name => {
  const parts = image_name.split("_");
  const image_id = parts[0] + "_" + parts[1] + "_" + parts[2];

  return image_id;
};

const getPositionFromImageName = image_name => {
  const parts = image_name.split("_");
  const image_id = getImageSet(image_name);
  const cfg = Constants.MAP.INSTANT_SEARCH.CONFIG[image_id];
  const col = parseInt(parts[parts.length - 1]);
  const row = parseInt(parts[parts.length - 2]);

  return _calculateExtentFromCollumnRow(col, row, cfg);
};

export {
  getImageSet,
  getGridFeatureFromPosition,
  getPositionFromImageName,
  getImageIdFromCoordinate
};
