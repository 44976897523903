<template>
  <div class="annotate">
    <v-container fill-height fluid pa-0 align-start>
      <v-row no-gutters class="top-toolbar">
        <v-col>
          <df-toolbar :fixedAddress="fixedAddress"></df-toolbar>
        </v-col>
      </v-row>

      <v-row no-gutters class="content-row">
        <v-col>
          <div class="map-view-container map-container">
            <map-view-search ref="map" />

            <df-block-detect v-if="enabledDetect" @onDetect="startDetect" />

            <df-instant-search-result
              :active="$store.state.session.session.detected"
              :loading="
                (Object.keys($store.state.session.search.results).length == 0 &&
                  $store.state.session.session.detected) ||
                  $store.state.session.loading
              "
              :items="$store.state.session.search.results"
              :expanded="$store.state.session.session.detected"
              :limit="$store.state.session.search.resultLimit"
              @search:limit-changed="
                $store.dispatch(
                  'session/updateSearchResultLimit',
                  $event.current
                )
              "
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DfToolbar from "@/components/general/DfToolbar.vue";
import MapViewSearch from "@/components/map/MapViewSearch.vue";
import DfBlockDetect from "../components/general/DfBlockDetect.vue";
import DfInstantSearchResult from "../components/instant-search/DfInstantSearchResult.vue";
import { SessionTypes } from "../store/session";
import { INSTANT_SEARCH_DEMO_DATA } from "../../data/DemoData.js";

export default {
  components: {
    DfToolbar,
    MapViewSearch,
    DfBlockDetect,
    DfInstantSearchResult
  },

  data() {
    return {
      enabledDetect: false,
      fixedAddress: true
    };
  },
  mounted() {
    this.setDefaultAddress();
    this.$store.dispatch("session/setSessionType", SessionTypes.search);
    this.resetSession();
  },

  methods: {
    setDefaultAddress() {
      const item = INSTANT_SEARCH_DEMO_DATA;
      this.$store.dispatch("session/updateSessionAddress", {
        location_name: item.text,
        address: item.place_name,
        latitude: item.geometry.coordinates[1],
        longitude: item.geometry.coordinates[0]
      });
    },

    resetSession() {
      this.$store.dispatch("session/updateSearchLocation", {
        id: "",
        coordinates: []
      });
      this.$store.dispatch("session/updateDetected", false);
      this.$store.dispatch("session/updateSearchResults", {});
    },

    async startDetect() {
      this.enabledDetect = false;
      await this.$store.dispatch("session/updateDetected", true);
      await this.$store.dispatch("session/updateSearchResults", {});
      await this.$store.dispatch("session/doSearch");
    }
  },

  computed: {},

  watch: {
    "$store.state.session.search.location"(val) {
      this.enabledDetect = val.length > 0 ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.annotate {
  background: darkolivegreen;
}
.content-row {
  position: relative;
}
</style>
