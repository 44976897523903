import { Fill, Stroke, Style } from "ol/style";
import { bboxPolygon, randomPosition } from "@turf/turf";
import {
  getGridFeatureFromPosition,
  getImageIdFromCoordinate,
  getImageSet,
  getPositionFromImageName
} from "../lib/gridItemQuery";

import BboxPolygon from "@turf/bbox-polygon";
import Collection from "ol/Collection";
import Constants from "../config/constants";
import { Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { INSTANT_SEARCH_DEMO_DATA } from "../../data/DemoData";
import { OverviewMap } from "ol/control";
import { Polygon } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { getCenter } from "ol/extent";
import mapControl from "./map-controller";
import { toLonLat } from "ol/proj";
import uuidv4 from "uuid/v4";

export const SearchSelectLayers = {
  ...Constants.SEARCH_LAYER_NAMES
};

const DEFAULT_SIZE = 180;

class SearchSelectControl {
  constructor(Vue, map, size) {
    this._eventBus = Vue;
    this._mapbox = Vue.$mapbox;
    this._map = map;
    this._selectedCenterCoordinate = [];
    this._layers = new Collection();
    this._size = size || DEFAULT_SIZE;
    this._enableSelect = false;

    this._currentMouseHoverFeature = null;
    this._currentClickedFeature = null;

    this._initializeAllLayers();

    if (process.env.NODE_ENV === "development") {
      window.dumpFeatures = () =>
        this.writeFeatures(SearchSelectLayers.LAYER_SELECTED, true);

      window.drawRandomFeatures = limit => this._drawRandomFeatures(limit);
    }
  }

  moveToDefaultLocation() {
    mapControl.moveToLocation(
      this._map,
      {
        lat: INSTANT_SEARCH_DEMO_DATA.geometry.coordinates[1],
        long: INSTANT_SEARCH_DEMO_DATA.geometry.coordinates[0]
      },
      true
    );
  }

  moveToSelected() {
    mapControl.moveToLocation(
      this._map,
      {
        lat: this._selectedCenterCoordinate[1],
        long: this._selectedCenterCoordinate[0]
      },
      false
    );
  }

  enableSelect(enable) {
    this._enableSelect = enable;
  }

  reset() {
    this._layers.forEach(layer => {
      layer.clear();
    });
  }

  init() {
    this._map.on("pointermove", evt => {
      if (evt.dragging) {
        return this._onDragging(evt);
      }
      this._onMouseMove(evt);
    });

    this._map.on("singleclick", evt => {
      this._onSingleClick(evt);
    });
  }

  _onMouseMove(event) {
    if (!this._enableSelect) {
      return;
    }

    const position = toLonLat(event.coordinate);
    const image_id = getImageIdFromCoordinate(position[0], position[1]);
    const { imageName, geometry } = getGridFeatureFromPosition(
      image_id,
      position[0],
      position[1]
    );

    if (geometry) {
      const feature = new GeoJSON({
        dataProjection: Constants.DEFAULT_DATA_PROJECTION,
        featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
      }).readFeature(geometry);

      feature.set("id", imageName);

      if (
        !this._currentMouseHoverFeature ||
        this._currentMouseHoverFeature.get("id") !== imageName
      ) {
        this._drawMovingROI(feature);
        this._currentMouseHoverFeature = feature;
      }
    }
  }

  _onDragging(event) {
    //pass
  }

  _turfGeometryToFeature(geometry, imageName) {
    const feature = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    }).readFeature(geometry);

    feature.set("id", imageName);

    return feature;
  }

  _onSingleClick(event) {
    if (!this._enableSelect) {
      return;
    }

    const position = toLonLat(event.coordinate);
    const image_id = getImageIdFromCoordinate(position[0], position[1]);
    const { imageName, geometry } = getGridFeatureFromPosition(
      image_id,
      position[0],
      position[1]
    );

    if (geometry) {
      const feature = this._turfGeometryToFeature(geometry, imageName);

      if (
        !this._currentClickedFeature ||
        this._currentClickedFeature.get("id") !== imageName
      ) {
        this._drawSelectedROI(feature);
        this._currentClickedFeature = feature;
      }

      this._eventBus.$emit("search:selected", {
        id: imageName,
        feature: this.writeFeatures(SearchSelectLayers.LAYER_SELECTED),
        coordinates: toLonLat(getCenter(feature.getGeometry().getExtent()))
      });
    }
  }

  _initializeLayer(name, style, zIndex) {
    let layer = this._map
      .getLayers()
      .getArray()
      .filter(item => item.values_.name === name)[0];

    //only init once
    if (!layer) {
      const defaultStyle = new Style({
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        stroke: new Stroke({ color: [0, 50, 255, 1], width: 2 })
      });

      if (!style) {
        style = () => [defaultStyle];
      }

      layer = new VectorLayer({
        name: name,
        source: new VectorSource({ wrapX: false }),
        style: style,
        zIndex
      });

      this._map.addLayer(layer);
      this._layers.set(name, layer);
    }
  }

  _initializeAllLayers() {
    this._eventBus.$emit("search:initializing");
    this._initializeLayer(
      SearchSelectLayers.LAYER_RESULT,
      new Style(
        {
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          stroke: new Stroke({ color: [240, 255, 0, 1], width: 2 })
        },
        1
      )
    );
    this._initializeLayer(
      SearchSelectLayers.LAYER_SELECTED,
      new Style(
        {
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          stroke: new Stroke({ color: [0, 50, 255, 1], width: 2 }),
          fill: new Fill({ color: [0, 50, 255, 0.05] })
        },
        2
      )
    );

    this._initializeLayer(
      SearchSelectLayers.LAYER_MOUSE,
      new Style(
        {
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          stroke: new Stroke({ color: [255, 0, 0, 1], width: 2 })
        },
        3
      )
    );
    this._eventBus.$emit("search:initialized");
  }

  setupOverviewMap(overviewMapMainLayer) {
    const selectedLayerOriginal = this.getLayer(
      SearchSelectLayers.LAYER_SELECTED
    );
    const resultLayerOriginal = this.getLayer(SearchSelectLayers.LAYER_RESULT);
    const selectedLayer = new VectorLayer({
      source: selectedLayerOriginal.getSource(),
      style: selectedLayerOriginal.getStyle()
    });
    const resultLayer = new VectorLayer({
      source: resultLayerOriginal.getSource(),
      style: resultLayerOriginal.getStyle()
    });
    const overviewMapControl = new OverviewMap({
      layers: [overviewMapMainLayer, resultLayer, selectedLayer],
      collapsible: true,
      collapsed: false,
      className: "ol-overviewmap ol-search-overviewmap"
    });

    this._map.addControl(overviewMapControl);
  }
  getLayer(layerName) {
    return this._layers.get(layerName);
  }

  _mousePositionFromEvent(evt) {
    return evt && evt.coordinate;
  }

  _drawPolygonFeature(layerName, feature) {
    let layer = this.getLayer(layerName);

    if (!layer) {
      throw new Error("Layer has not registered");
    }

    layer.getSource().addFeature(feature);
  }

  _drawRoi(
    layerName,
    coordinate,
    { width = DEFAULT_SIZE, height = DEFAULT_SIZE }
  ) {
    let layer = this._layers.get(layerName);

    if (!layer) {
      throw new Error("Layer has not registered");
    }

    const center = coordinate;

    layer.getSource().addFeature(
      new Feature(
        new Polygon([
          [
            [center[0] - width, center[1] - height],
            [center[0] + width, center[1] - height],
            [center[0] + width, center[1] + height],
            [center[0] - width, center[1] + height]
          ]
        ])
      )
    );
  }

  _clearROIs(layerName) {
    let layer = this._layers.get(layerName);
    layer.getSource().clear();
  }

  _drawSelectedROI(feature) {
    //clear previous feature
    this._clearROIs(SearchSelectLayers.LAYER_SELECTED);
    this._drawPolygonFeature(SearchSelectLayers.LAYER_SELECTED, feature);
  }

  _drawMovingROI(feature) {
    //clear previous feature
    this._clearROIs(SearchSelectLayers.LAYER_MOUSE);
    this._drawPolygonFeature(SearchSelectLayers.LAYER_MOUSE, feature);
  }

  clearSearchResults() {
    const resultLayer = this._layers.get(
      Constants.SEARCH_LAYER_NAMES.LAYER_RESULT
    );
    resultLayer.getSource().clear();
  }

  loadSearchResults(results) {
    const features = results.result_tile_ids.map(id => {
      const featureId = id.replace(".png", "");
      const position = getPositionFromImageName(featureId);
      const geometry = bboxPolygon(position);
      const feature = this._turfGeometryToFeature(geometry, featureId);
      return feature;
    });

    const resultLayer = this._layers.get(
      Constants.SEARCH_LAYER_NAMES.LAYER_RESULT
    );

    resultLayer.getSource().clear();
    resultLayer.getSource().addFeatures(features);

    this._eventBus.$emit("search:results:loaded");

    const writer = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    });
    const processedFeatures = [];

    features.forEach(feature => {
      const imageSet = getImageSet(feature.get("id"));
      const thumbnailUrl =
        Constants.MAP.INSTANT_SEARCH.CONFIG[imageSet].grid_image_url +
        feature.get("id") +
        ".png";

      feature.set("thumbnailUrl", thumbnailUrl);
      processedFeatures.push(feature);
    });

    const featuresAsGeoJSON = writer.writeFeaturesObject(processedFeatures);

    return featuresAsGeoJSON;
  }

  loadFeatures(layerName, featuresAsGeoJSON, shouldClear = false) {
    this._eventBus.$emit("search:results:loading");
    const layer = this._layers.get(layerName);

    if (shouldClear) {
      this._clearROIs(layerName);
    }

    const reader = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    });

    layer.getSource().addFeatures(reader.readFeatures(featuresAsGeoJSON));
    this._eventBus.$emit("search:results:loaded");
  }

  writeFeatures(layerName, shouldClear = false) {
    const layer = this._layers.get(layerName);
    const writer = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    });

    const geoData = writer.writeFeaturesObject(layer.getSource().getFeatures());

    if (shouldClear) {
      this._clearROIs(layerName);
    }
    return geoData;
  }

  getBoundingBox() {
    const extent = this._map.getView().calculateExtent();
    const topLeft = [extent[0], extent[3]];
    const bottomRight = [extent[2], extent[1]];
    const boundingBox = {
      topLeft: toLonLat(topLeft),
      bottomRight: toLonLat(bottomRight)
    };

    return { extent, bboxLonLat: boundingBox };
  }

  getTurfBBoxLonLat(bboxLonLat) {
    const bboxPolygon = BboxPolygon([
      bboxLonLat.bottomRight[0],
      bboxLonLat.bottomRight[1],
      bboxLonLat.topLeft[0],
      bboxLonLat.topLeft[1]
    ]);

    return bboxPolygon;
  }

  createRandomFeatures(limit, useLonLat = false) {
    const { extent } = this.getBoundingBox();
    let results = [];

    for (let i = 0; i < limit; i += 1) {
      let position = randomPosition(extent);

      if (useLonLat) {
        position = toLonLat(position);
      }
      results.push(position);
    }

    return results;
  }

  _drawRandomFeatures(limit) {
    const positions = this.createRandomFeatures(limit);
    let index = 0;
    let interval = setInterval(() => {
      let position = positions[index];

      this._drawRoi(SearchSelectLayers.LAYER_RESULT, position, {
        width: this._size,
        height: this._size
      });

      index += 1;

      if (index >= limit) {
        clearInterval(interval);
      }
    }, 100);
  }

  getStaticImageUrlAtPosition(coordinate, size = 180, zoom = 16) {
    const request = this._mapbox.staticImage.getStaticImage({
      ownerId: "mapbox",
      styleId: "satellite-v9",
      width: size,
      height: size,
      position: {
        coordinates: coordinate,
        zoom: zoom
      }
    });

    return request.url();
  }

  collectionToGeoJSON(collection) {
    const layer = new VectorLayer({
      name: "temp",
      source: new VectorSource({ wrapX: false })
    });
    const zoomLevel = this._map.getView().getZoom();

    collection.forEach(center => {
      const width = this._size;
      const height = width;
      const feature = new Feature(
        new Polygon([
          [
            [center[0] - width, center[1] - height],
            [center[0] + width, center[1] - height],
            [center[0] + width, center[1] + height],
            [center[0] - width, center[1] + height]
          ]
        ])
      );

      const id = uuidv4();
      const thumbnailUrl = this.getStaticImageUrlAtPosition(
        toLonLat(center),
        toLonLat(this._size)[0],
        zoomLevel,
        image => {
          this._eventBus.$emit("search:result:image", { id, image });
        }
      );
      feature.setId(id);
      feature.setProperties({
        thumbnailUrl
      });
      layer.getSource().addFeature(feature);
    });

    const writer = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    });

    const geoData = writer.writeFeatures(layer.getSource().getFeatures());
    return JSON.parse(geoData);
  }

  hideMouseLayer() {
    this._clearROIs(SearchSelectLayers.LAYER_MOUSE);
  }
}

export default SearchSelectControl;
