<template>
  <div
    id="map"
    class="map instant-search-map"
    :class="{ 'ol-layer-blur': enableBlurMap }"
    @mouseout="onMouseOut"
  ></div>
</template>

<script>
import bus from "@/event-bus";
import Vue from "vue";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Tile as TileLayer } from "ol/layer";
import { XYZ as XYZSource } from "ol/source";
import mapControl from "../../lib/map-controller";
import { fromLonLat, transformExtent } from "ol/proj";
import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate";
import SearchSelectControl from "../../lib/search-select-controller";
import Constants from "../../config/constants";

export default {
  name: "map-view",
  props: {
    enableBlurMap: { type: Boolean, default: false },
    allowZoom: { type: Boolean, default: true }
  },
  mounted() {
    const rasterSource = new XYZSource({
      url: Constants.MAP.INSTANT_SEARCH.MAP_URL
    });

    const raster = new TileLayer({
      source: rasterSource
    });

    const layers = [raster];

    this.map = new Map({
      layers: layers,
      controls: []
    });

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      className: "search-mouse-control",
      projection: Constants.DEFAULT_DATA_PROJECTION
    });
    this.map.addControl(mousePositionControl);

    mapControl.setZoomEnable(this.map, this.allowZoom);

    this.map.setTarget("map");

    const viewMap = new View({
      center: fromLonLat(Constants.MAP.INSTANT_SEARCH.DEFAULT_COORDINATES),
      extent: transformExtent(
        Constants.MAP.INSTANT_SEARCH.MAP_EXTENT,
        "EPSG:4326",
        "EPSG:3857"
      ),
      zoom: Constants.MAP.INSTANT_SEARCH.DEFAULT_ZOOM,
      maxZoom: Constants.MAP.INSTANT_SEARCH.MAX_ZOOM,
      minZoom: Constants.MAP.INSTANT_SEARCH.MIN_ZOOM,
      constrainOnlyCenter: true
    });

    this.map.setView(viewMap);

    bus.$off("result-item-selected");
    bus.$off("search:selected");

    bus.$on("result-item-selected", item => this.moveToLocation(item));
    bus.$on("search:selected", evt => this.setSelectGridItem(evt));

    const overviewMapMainLayer = new TileLayer({
      source: new XYZSource({
        url: Constants.MAP.INSTANT_SEARCH.MAP_URL
      })
    });

    this.searchSelectControl = new SearchSelectControl(bus, this.map);
    this.searchSelectControl.init();
    this.searchSelectControl.moveToDefaultLocation();
    this.searchSelectControl.setupOverviewMap(overviewMapMainLayer);

    this.checkEnableInteraction(() => {
      this.searchSelectControl.enableSelect(true);
    });

    this.navigateDefaultView(() => {
      this.setDefaultSelect();
    });

    //assign the map to global vue instance
    this.map.controller = this.searchSelectControl;
    Vue.prototype.$map = this.map;
  },

  watch: {
    "$store.state.session.session.address"() {
      this.checkEnableInteraction(() => this.navigateDefaultView());
    },
    "$store.state.preset.selected"(val) {
      if (val) {
        const preset = this.$store.state.preset.presets.results.find(
          item => item.id === val
        );
        this.loadPreset(preset);
      }
    }
  },
  methods: {
    setSelectGridItem(evt) {
      this.$store.dispatch("session/updateSearchLocation", {
        id: evt.id,
        coordinates: evt.coordinates
      });
      this.$store.dispatch("session/updateDetected", false);
    },
    setDefaultSelect() {
      const initialSelect = {
        id: "shanghai_19_01_1385_0249",
        feature: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [121.56084942890476, 31.37558537766178],
                    [121.56142440855594, 31.37558537766178],
                    [121.56142440855594, 31.376161227371384],
                    [121.56084942890476, 31.376161227371384],
                    [121.56084942890476, 31.37558537766178]
                  ]
                ]
              },
              properties: { id: "shanghai_19_01_1385_0249" }
            }
          ]
        },
        coordinates: [121.56113691873036, 31.375873302957757]
      };
      this.setSelectGridItem(initialSelect);
      this.searchSelectControl.loadFeatures(
        Constants.SEARCH_LAYER_NAMES.LAYER_SELECTED,
        initialSelect.feature
      );
    },
    navigateDefaultView(cb) {
      mapControl.animateTo(
        this.map,
        fromLonLat(Constants.MAP.INSTANT_SEARCH.DEFAULT_COORDINATES),
        Constants.MAP.INSTANT_SEARCH.DEFAULT_ZOOM,
        () => {
          mapControl.setZoomEnable(this.map, true);
          this.searchSelectControl.enableSelect(true);
          if (cb && typeof cb === "function") {
            cb();
          }
        }
      );
    },

    loadPreset(preset) {
      const center = [preset.longitude, preset.latitude];
      const selected = preset.extras.features.SelectedLayer;
      const results = preset.extras.features.ResultLayer;
      const zoomLevel = preset.extras.zoom_level;

      this.$store.dispatch("session/updateLoadingPresetStatus", true);
      this.searchSelectControl.loadFeatures(
        Constants.SEARCH_LAYER_NAMES.LAYER_SELECTED,
        selected,
        true
      );
      this.searchSelectControl.loadFeatures(
        Constants.SEARCH_LAYER_NAMES.LAYER_RESULT,
        results,
        true
      );
      //load coordinates
      this.$store.dispatch("session/updateSessionAddress", {
        location_name: preset.location_name,
        address: preset.address,
        longitude: preset.longitude,
        latitude: preset.latitude
      });
      //load location
      this.$store.dispatch("session/updateSearchLocation", {
        id: "",
        coordinates: []
      });
      //move to location
      mapControl.animateTo(
        this.$map,
        fromLonLat(center),
        zoomLevel || Constants.MAP.INSTANT_SEARCH.DEFAULT_ZOOM,
        () => {
          //load result image list
          this.$store.dispatch("session/updateSearchResults", results);
          this.$store.dispatch("session/updateDetected", true);
          setTimeout(() => {
            this.$store.dispatch("session/updateLoadingPresetStatus", false);
          }, 500);
        }
      );
    },
    checkEnableInteraction(callback) {
      if (
        this.$store.state.session.session.address &&
        this.$store.state.session.session.address.length > 0
      ) {
        callback();
      }
    },
    onMouseOut() {
      this.searchSelectControl.hideMouseLayer();
    },
    moveToLocation(item) {
      const northwest = item.item.geometry.coordinates[0][0];
      const southeast = item.item.geometry.coordinates[0][2];

      const coordinates = {
        long: (northwest[0] + southeast[0]) / 2,
        lat:
          (northwest[1] + southeast[1]) / 2 -
          Math.abs(northwest[1] - southeast[1]) / 2 //delta to make feature at center
      };
      mapControl.animateTo(
        this.map,
        fromLonLat([coordinates.long, coordinates.lat]),
        this.map.getView().getMaxZoom()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~ol/ol.css";
.map {
  height: 100%;
  background: #f8f4f0;
}
</style>
