<template>
  <transition name="slide">
    <div
      v-show="active"
      class="instant-search-wrapper"
      :class="{ active: isToggled, inactive: !isToggled }"
    >
      <v-container fill-height pa-4 pt-1 fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-toolbar dense flat class="no-padding-content">
              <v-toolbar-title class="subtitle-1 font-weight-bold">
                {{ $t("search_result.results") }}:
                <span v-if="!loading">
                  {{ items && items.features && items.features.length }}
                </span>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <template>
                <v-menu offset-y>
                  <v-list dense>
                    <v-list-item
                      v-for="(num, index) in resultsCount"
                      :key="index"
                      :value="num"
                      @click="selectedLimit = num"
                    >
                      <v-list-item-title>{{ num }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn icon @click="toggle">
                  <v-icon v-if="isToggled">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
            </v-toolbar>
          </v-col>
        </v-row>

        <div class="search-results">
          <v-container fill-height justify-center v-if="showProgress">
            <v-progress-linear
              indeterminate
              color="secondary"
              rounded
              striped
              height="5"
              class="loading-progress"
            ></v-progress-linear>
          </v-container>

          <v-row class="flex-nowrap justify-start" v-else>
            <v-col v-for="item in items.features" :key="item.id" class="d-flex">
              <v-card
                flat
                tile
                class="d-flex image-card instant-search-thumbnail"
                @click="selectItem(item)"
              >
                <v-img
                  :src="item.properties.thumbnailUrl"
                  :lazy-src="item.properties.thumbnailUrl"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div class="image-name">
                  {{ item.properties.id }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </transition>
</template>

<script>
import bus from "@/event-bus";

export default {
  props: {
    items: { type: Object },
    loading: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
    limit: { type: Number }
  },
  data() {
    return {
      isToggled: false,
      resultsCount: [15, 25, 50, 75, 100],
      selectedLimit: 15
    };
  },
  computed: {
    showProgress() {
      return this.loading;
    }
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled;
    },

    selectItem(item) {
      bus.$emit("result-item-selected", { item: item });
    }
  },
  watch: {
    selectedLimit(newVal, oldVal) {
      if (newVal > 15) {
        this.selectedLimit = 15;
        return this.$store.dispatch("toggleLoginDialog");
      }
      this.$emit("search:limit-changed", { current: newVal, previous: oldVal });
    },
    expanded(newVal) {
      this.isToggled = newVal;
    },
    limit(newVal) {
      this.selectedLimit = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-name {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  color: white;
  left: 0;
  right: 0;
  height: 16px;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
}
.slide-enter-active,
.slide-leave-active {
  transition: margin-bottom 0.8s ease-out;
}
.slide-enter,
.slide-leave-to {
  margin-bottom: -200px;
}
.slide-enter-to,
.slide-leave {
  margin-bottom: 0px;
}
.instant-search-wrapper {
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-height: 55px;
  transition: all 0.2s ease;
}
.active {
  height: 250px;
}
.inactive {
  height: 55px;
}
.search-results {
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  height: 180px;
  .image-card {
    height: 150px;
    width: 150px;
    flex: 1;
    img {
      height: 140px;
      width: 140px;
    }
  }
}
.loading-progress {
  width: 300px;
}
</style>
